import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { serializeNode } from "../utils/wysiwyg"
import fetchJsonp from "fetch-jsonp"

import SmartFeedFiltersComponent from "../components/SmartFeedWithFilters"

import renderHTML from "react-render-html"

require("es6-promise").polyfill()

export default function HomepagePagePage({
  pageContext: { integratorData, contentMetaData, contentDataData },
  props,
}) {
  const [vacancyLoading, setVacancyLoading] = useState(true)

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{contentMetaData.title}</title>
        <meta
          name="description"
          content="Find your new career at Longleat today."
        />
      </Helmet>

      <div className="home">
        <section className="app-content">
          {contentDataData && contentDataData.length > 0 && (
            <div>
              <div className="app-hero-wrapper app-hero-home app-hero-video">
                <div className="app-hero-video-wrap">
                  <div className="video_item">
                    <div className="video_position">
                      <video
                        preload="true"
                        muted
                        autoPlay={true}
                        loop
                        playsInline
                      >
                        <source
                          src="https://cdn1.accropress.com/LongleatCareers/longleat_safari.mp4"
                          type="video/mp4"
                        />
                        <source
                          src="https://cdn1.accropress.com/LongleatCareers/longleat_safari.webm"
                          type="video/webm"
                        />
                        <source
                          src="https://cdn1.accropress.com/LongleatCareers/longleat_safari.ogg"
                          type="video/ogg"
                        />
                        <img
                          src={contentDataData[0].children[0].value}
                          alt="Your browser does not support the <video> tag"
                        />
                      </video>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="app-hero-background-image"
                  style={{
                    backgroundImage: `url(${contentDataData[0].children[0].value})`,
                  }}
                ></div>
                <div className="app-hero-background-overlay"></div>
                <div className="app-hero-content-center"></div>
                <div className="app-hero-content-bottom"></div> */}
              </div>

              <div className="app-content-content">
                <div className="content-block-intro">
                  <div className="container">
                    <div className="homepage-opener-section row">
                      <div className="col-xs-12 homepage-opener-section-center">
                        <h1>{contentMetaData.title}</h1>
                        <div className="page-content-wysiwyg">
                          {JSON.parse(contentDataData[0].children[1].value) &&
                            JSON.parse(contentDataData[0].children[1].value)
                              .length > 0 &&
                            JSON.parse(
                              contentDataData[0].children[1].value
                            ).map((item, i) => (
                              <div key={i}>
                                {renderHTML(serializeNode(item))}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="content-block-light">
                  <div className="content-block-vacancies">
                    <div className="container">
                      <SmartFeedFiltersComponent
                        acancyLoading={vacancyLoading}
                        setVacancyLoading={setVacancyLoading}
                        apiKey={"FNxiW9hQTwcXaAW0rcVo5NSC1ui8BTNfGhk9FN0-uZw"}
                        group={false}
                        gdprKey=""
                        CompanyGroupName="Longleat"
                        VacancyPageBaseURL="https://jobs.longleat.co.uk/vacancies/vacancy/"
                        ReturnToSearchURL="https://jobs.longleat.co.uk/vacancies/"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    </Layout>
  )
}
